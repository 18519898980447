import React from 'react';
import './Announcement.css';
import { useNavigate } from 'react-router-dom';
import Header from '../HeaderMain/Header3'

import img1 from '../AssetsMain/Images/R_and_D.jpeg';
import img2 from '../AssetsMain/Images/Company_Docs.jpeg';

const Announcement_list = [
    {
        title: 'Research - No Code EDA and ML Platform',
        description: [
            'White paper based on design, development and deployment of a No-Code EDA and ML platform for data analytics applications.',
        ],
        img: img1,
        route_path: '/research1.pdf',
    },
    {
        title: 'Research - Highly optimized Edge LPR Pipeline',
        description: [
            'White paper based on design, development and deployment of a real-time LPR pipeline for Edge devices mainly utilizing high TensorRT optimization.',
        ],
        img: '',
        route_path: '/research2.pdf',
    },
    {
        title: 'Company Certificates',
        description: [
            'Company related certificates',
        ],
        img: img2,
        route_path: '/form.pdf',
    },
];

function Announcement() {
    const navigate = useNavigate();

    const handleRedirect = (route) => {
        navigate(route);
    };

    return (
        <div className='announcement-container'>
            <Header />
            <h1 className='announcement-title'>Announcement</h1>
            {Announcement_list.map((announcement, index) => (
                <div className='announcement-list' key={index}>
                    <div className='left-cont'>
                        <h1 className='announce-title'>{announcement.title}</h1>
                        {announcement.description.map((desc, idx) => (
                            <div key={idx}>{desc}</div>
                        ))}
                        <button className='view-btn' onClick={() => handleRedirect(announcement.route_path)}>View</button>
                    </div>
                    <div className='right-cont'>
                        {announcement.img && (
                            <img className='announce-img' src={announcement.img} alt={announcement.title} />
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Announcement;
