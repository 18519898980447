import React, { useState } from 'react';
import './Header.css'
// import { Link, useNavigate } from 'react-router-dom'
import Logo from '../Assets/Images/Deepcept.svg';



function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    // const navigate = useNavigate();


    const handleMenuToggle = () => {
      setIsMenuOpen(!isMenuOpen);
    };
    // const handleScroll = (sectionId) => {
    //     setTimeout(() => {
    //         document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
    //     }, 10);
    //     setIsMenuOpen(false); // Close the menu after clicking a link
    // };

    // const handleRedirect = (route) => {
    //     navigate(route); // Navigate to the login page
    // };
  
  return (
    <div className='header-container'>
        <a href='/' className='header-logo'>
        <img className='App-logo' src={Logo} alt="DeepceptAI" />
        </a>
        <div className='hamburger' onClick={handleMenuToggle}>
          ☰
        </div>
        <div className={`mode ${isMenuOpen ? 'open' : ''}`}>
            <div className='nav-content'>
                {/* <ul>
                    <li>
                        <button className='header-link' href='/home' onClick={() => handleScroll('home')}>Home</button>
                    </li>
                    <li>
                        <button className='header-link' onClick={() => handleRedirect('/internship')}>Internship</button>
                    </li>
                    <li>
                        <button className='header-link' href='/products' onClick={() => handleScroll('products')}>Products</button>
                    </li>
                    <li>
                        <button className='header-link' href='/service' onClick={() => handleScroll('service')}>Services</button>
                    </li>
                    <li>
                        <button className='header-link' href='/about' onClick={() => handleScroll('about')}>About</button>
                    </li>
                    <li>
                        <button className='header-link' href='/contact' onClick={() => handleScroll('contact')}>Contact</button>
                    </li>
                    <li>
                        <button className='header-link' onClick={() => handleRedirect('/Announcement')}>Announcement</button>
                    </li>
                    <li>
                        <Link className='header-link' to='/login'>
                            <button className='login-btn'>
                                LOGIN
                            </button>
                        </Link>
                    </li>
                </ul> */}
            </div>
        </div>
    </div>
  )
}

export default Header
