import React from 'react'
import './Home2.css'
import { useNavigate } from 'react-router-dom'

import product_img from '../AssetsMain/Images/product_solution.jpg'
import services_img from '../AssetsMain/Images/services.jpeg'
import internships_img from '../AssetsMain/Images/internships.jpeg' 

const About = [
    {
        title: "Who We Are",
        description: [
            "Deepcept AI is a product development company that also offers various software services especially in the field of AI, Machine learning and Deep learning. ",
            "We also have an active internship program for students and graduates and corporate upskilling for  industry. "
        ]
    }
];

const Offers = [
    { 
        id: 1, 
        title: 'Product Development', 
        image: product_img, 
        description: 'Product development involves the complete process of bringing a new product to market, from initial idea generation and design to testing, production, and launch. It aims to meet customer needs while ensuring profitability and market viability.',
        link: 'products',
    },
    {
        id: 2,
        title: 'Services',
        image: services_img,
        description: 'Our services encompass a wide range of solutions tailored to meet the specific needs of businesses and individuals. From consultation and strategy development to implementation and ongoing support, we aim to deliver value through expertise and dedicated service.',
        link: 'service',
    },
    {
        id: 3,
        title: 'Internships',
        image: internships_img,
        description: 'Internships offer hands-on experience in various fields, allowing students and recent graduates to develop practical skills and gain insight into their desired industries. Our internship program provides mentorship, real-world projects, and opportunities to build a professional network.',
        link: '/internship',
    }
];


function Home2() {
    const navigate = useNavigate();

    // Function to handle scrolling for sections and navigation for links
    const handleClick = (offer) => {
        if (offer.id === 1 || offer.id === 2) {
            // For the first two offers, scroll to the section
            const sectionId = offer.link;
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        } else if (offer.id === 3) {
            // For the third offer, navigate to the internship link
            navigate(offer.link);
        }
    };

    return (
        <section id='home2' className='home2-container'>
            {About.map(Abt => (
                <div className='abt' key={Abt.title}>
                    <h1 className='m-title'>{Abt.title}</h1>
                    <div className='m-des'>
                        <h3>{Abt.description}</h3>
                    </div>
                </div>
            ))}
            <div className='h2-left-side'>
                <br></br>
                <h1 className='semi-title'>What We Offer</h1>
                <div className='main-box'>
                    {Offers.map(offer => (
                        <div 
                            className='box' 
                            key={offer.id} 
                            onClick={() => handleClick(offer)}
                        >
                            <img className='box-img' src={offer.image} alt={offer.title} />
                            <h2>{offer.title}</h2>
                            <div className='description-p'>
                                <p className='p-desc'>{offer.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Home2;
