import { lazy } from 'react';

// Components
import Home from './Components/Home/Home'
import Header1 from './Components/Header/Header'
import Login from './Components/Firebase/Login'


// Courses
const Course1 = lazy(() => import('./Components/Courses/Data_Analytics/Data_Analytics'));
const Course2 = lazy(() => import('./Components/Courses/Data_Science/Data_Science'));
const Course3 = lazy(() => import('./Components/Courses/Computer_Vision/Computer_Vision'));
const Course4 = lazy(() => import('./Components/Courses/Software_development/Software_development'));
const Course5 = lazy(() => import('./Components/Courses/AI/Ai_engineer'));
const Course6 = lazy(() => import('./Components/Courses/ML/ML'));
const Course7 = lazy(() => import('./Components/Courses/DL/DL'));
const Course8 = lazy(() => import('./Components/Courses/Gen_Ai/Gen_ai'));
const Course9 = lazy(() => import('./Components/Courses/NLP/nlp'));
const Course10 = lazy(() => import('./Components/Courses/Robotics/Robotics'));
const Course11 = lazy(() => import('./Components/Courses/Comprehensive_AI_projects/Comprehensive_AI_projects'));

// Lessons
// const AIIntro = lazy(() => import('./Components/lessons/Ai_Intro/Ai_Intro'));
const Linux = lazy(() => import('./Components/lessons/Linux/Linux'));
const LN = lazy(() => import('./Components/lessons/Linux_Network/Linux_Network'));
const Jetson = lazy(() => import('./Components/lessons/Jetson/Jetson'));
const Conda = lazy(() => import('./Components/lessons/Conda/Conda'));
const Jupyter = lazy(() => import('./Components/lessons/Jupyter/Jupyter'));
const Python = lazy(() => import('./Components/lessons/Python/Python'));
const Numpy = lazy(() => import('./Components/lessons/Numpy/Numpy'));
const Pandas = lazy(() => import('./Components/lessons/Pandas/Pandas'));
const Matplotlib = lazy(() => import('./Components/lessons/Matplotlib/Matplotlib'));
const Colab = lazy(() => import('./Components/lessons/Colab/Colab'));
const Kaggle = lazy(() => import('./Components/lessons/Kaggle/Kaggle'));
const VSCode = lazy(() => import('./Components/lessons/VSCode/VSCode'));
const Github = lazy(() => import('./Components/lessons/Github/Github'));
const Docker = lazy(() => import('./Components/lessons/Docker/Docker'));
const OpenCv = lazy(() => import('./Components/lessons/OpenCV/OpenCV'));
const Cv = lazy(() => import('./Components/lessons/CV/Cv'));
const Tensorflow = lazy(() => import('./Components/lessons/Tensorflow/Tensorflow'));
const Pytorch = lazy(() => import('./Components/lessons/Pytorch/Pytorch'));
const Ml = lazy(() => import('./Components/lessons/ML/Ml'));
const Dl = lazy(() => import('./Components/lessons/DL/Dl'));
const Nlp = lazy(() => import('./Components/lessons/NLP/Nlp'));
const Cnn = lazy(() => import('./Components/lessons/CNN/Cnn'));
const Yolo = lazy(() => import('./Components/lessons/Yolo/Yolo'));
const GenAI = lazy(() => import('./Components/lessons/GenAI/GenAI'));
const DataScience = lazy(() => import('./Components/lessons/Data_Science/Data_Science'));
const DataAnalytics = lazy(() => import('./Components/lessons/Data_Analytics/Data_Analytics'));


// Modules
const Module2 = lazy(() => import('./Components/Modules/Linux'));
const Module3 = lazy(() => import('./Components/Modules/Linux_Network'));
const Module4 = lazy(() => import('./Components/Modules/Conda'));
const Module5 = lazy(() => import('./Components/Modules/Jupyter'));
const Module6 = lazy(() => import('./Components/Modules/Colab'));
const Module7 = lazy(() => import('./Components/Modules/Vscode'));
const Module8 = lazy(() => import('./Components/Modules/Kaggle'));
const Module9 = lazy(() => import('./Components/Modules/Jetson'));
const Module10 = lazy(() => import('./Components/Modules/Python'));
const Module11 = lazy(() => import('./Components/Modules/Numpy'));
const Module12 = lazy(() => import('./Components/Modules/Pandas'));
const Module13 = lazy(() => import('./Components/Modules/Matplotlib'));
const Module14 = lazy(() => import('./Components/Modules/Github'));
const Module15 = lazy(() => import('./Components/Modules/Docker'));
const Module16 = lazy(() => import('./Components/Modules/Opencv'));
const Module17 = lazy(() => import('./Components/Modules/Cv'));
const Module18 = lazy(() => import('./Components/Modules/Tensorflow'));
const Module19 = lazy(() => import('./Components/Modules/Pytorch'));
const Module20 = lazy(() => import('./Components/Modules/Ml'));
const Module21 = lazy(() => import('./Components/Modules/Dl'));
const Module22 = lazy(() => import('./Components/Modules/Cnn'));
const Module23 = lazy(() => import('./Components/Modules/Nlp'));
const Module24 = lazy(() => import('./Components/Modules/Yolo'));
const Module25 = lazy(() => import('./Components/Modules/Genai'));
const Module26 = lazy(() => import('./Components/Modules/DataScience'));
const Module27 = lazy(() => import('./Components/Modules/DataAnalytics'));

// Interviews
const Inter1 = lazy(() => import('./Components/Interview/Menulist'));
const AIintroInterview = lazy(() => import('./Components/Interview/Interview_modules/AIintro'));
const CnnInterview = lazy(() => import('./Components/Interview/Interview_modules/CNN'));
const CvInterview = lazy(() => import('./Components/Interview/Interview_modules/ComputerVision'));
const CondaInterview = lazy(() => import('./Components/Interview/Interview_modules/Conda'));
const DAInterview = lazy(() => import('./Components/Interview/Interview_modules/DataAnalytics'));
const DSInterview = lazy(() => import('./Components/Interview/Interview_modules/DataScience'));
const DlInterview = lazy(() => import('./Components/Interview/Interview_modules/DeepLearning'));
const DockerInterview = lazy(() => import('./Components/Interview/Interview_modules/Docker'));
const GenaiInterview = lazy(() => import('./Components/Interview/Interview_modules/GenAI'));
const GithubInterview = lazy(() => import('./Components/Interview/Interview_modules/Github'));
const ColabInterview = lazy(() => import('./Components/Interview/Interview_modules/GoogleColab'));
const JupyterInterview = lazy(() => import('./Components/Interview/Interview_modules/Jupyter'));
const KaggleInterview = lazy(() => import('./Components/Interview/Interview_modules/Kaggle'));
const LinuxInterview = lazy(() => import('./Components/Interview/Interview_modules/Linux'));
const LinuxNetworkingInterview = lazy(() => import('./Components/Interview/Interview_modules/LinuxNetworking'));
const MlInterview = lazy(() => import('./Components/Interview/Interview_modules/MachineLearning'));
const MatplotlibInterview = lazy(() => import('./Components/Interview/Interview_modules/Matplotlib'));
const NlpInterview = lazy(() => import('./Components/Interview/Interview_modules/NLP'));
const NumpyInterview = lazy(() => import('./Components/Interview/Interview_modules/Numpy'));
const OpenCVInterview = lazy(() => import('./Components/Interview/Interview_modules/OpenCV'));
const PandasInterview = lazy(() => import('./Components/Interview/Interview_modules/Pandas'));
const PythonInterview = lazy(() => import('./Components/Interview/Interview_modules/PythonProgramming'));
const PytorchInterview = lazy(() => import('./Components/Interview/Interview_modules/Pytorch'));
const TensorflowInterview = lazy(() => import('./Components/Interview/Interview_modules/Tensorflow'));
const VscodeInterview = lazy(() => import('./Components/Interview/Interview_modules/VSCode'));
const YoloInterview = lazy(() => import('./Components/Interview/Interview_modules/Yolo'));


// PDFs
// const AIIntropdf = lazy(() => import('./Components/PdfReader/Ai_intro'));
const Linuxpdf = lazy(() => import('./Components/PdfReader/Linux_pdf'));
const LinuxNetworkpdf = lazy(() => import('./Components/PdfReader/Linux_Network_pdf'));
const Jetsonpdf = lazy(() => import('./Components/PdfReader/Jetson_pdf '));
const Condapdf = lazy(() => import('./Components/PdfReader/Conda_pdf '));
const Jupyterpdf = lazy(() => import('./Components/PdfReader/Jupyter_pdf'));
const Pythonpdf = lazy(() => import('./Components/PdfReader/Python_pdf '));
const Numpypdf = lazy(() => import('./Components/PdfReader/Numpy_pdf '));
const Pandaspdf = lazy(() => import('./Components/PdfReader/Pandas_pdf '));
const Matplotlibpdf = lazy(() => import('./Components/PdfReader/Matplotlib_pdf '));
const Colabpdf = lazy(() => import('./Components/PdfReader/Colab_pdf '));
const Kagglepdf = lazy(() => import('./Components/PdfReader/Kaggle_pdf '));
const VSCodepdf = lazy(() => import('./Components/PdfReader/Vscode_pdf '));
const Githubpdf = lazy(() => import('./Components/PdfReader/Github_pdf '));
const Dockerpdf = lazy(() => import('./Components/PdfReader/Docker_pdf '));
const OpenCvpdf = lazy(() => import('./Components/PdfReader/Opencv_pdf'));
const Cvpdf = lazy(() => import('./Components/PdfReader/CV_pdf '));
const Tensorflowpdf = lazy(() => import('./Components/PdfReader/Tensorflow_pdf '));
const Pytorchpdf = lazy(() => import('./Components/PdfReader/Pytorch_pdf '));
const Mlpdf = lazy(() => import('./Components/PdfReader/ML_pdf '));
const Dlpdf = lazy(() => import('./Components/PdfReader/DL_pdf'));
const Nlppdf = lazy(() => import('./Components/PdfReader/NLP_pdf '));
const Cnnpdf = lazy(() => import('./Components/PdfReader/CNN_pdf '));
const Yolopdf = lazy(() => import('./Components/PdfReader/Yolo_pdf '));
const GenAIpdf = lazy(() => import('./Components/PdfReader/Genai_pdf'));
const DSpdf = lazy(() => import('./Components/PdfReader/Data_Science_pdf'));
const DApdf = lazy(() => import('./Components/PdfReader/Data_Analytics_pdf'));


// PPTs
// const AIIntroppt = lazy(() => import('./Components/PPTReader/'));
const Linuxppt = lazy(() => import('./Components/PPTReader/Linux_pdf'));
const LinuxNetworkppt = lazy(() => import('./Components/PPTReader/Linux_Network_pdf'));
const Jetsonppt = lazy(() => import('./Components/PPTReader/Jetson_pdf '));
const Condappt = lazy(() => import('./Components/PPTReader/Conda_pdf '));
const Jupyterppt = lazy(() => import('./Components/PPTReader/Jupyter_pdf'));
const Pythonppt = lazy(() => import('./Components/PPTReader/Python_pdf '));
const Numpyppt = lazy(() => import('./Components/PPTReader/Numpy_pdf '));
const Pandasppt = lazy(() => import('./Components/PPTReader/Pandas_pdf '));
const Matplotlibppt = lazy(() => import('./Components/PPTReader/Matplotlib_pdf '));
const Colabppt = lazy(() => import('./Components/PPTReader/Colab_pdf '));
const Kaggleppt = lazy(() => import('./Components/PPTReader/Kaggle_pdf '));
const VSCodeppt = lazy(() => import('./Components/PPTReader/Vscode_pdf '));
const Githubppt = lazy(() => import('./Components/PPTReader/Github_pdf '));
const Dockerppt = lazy(() => import('./Components/PPTReader/Docker_pdf '));
const OpenCvppt = lazy(() => import('./Components/PPTReader/Opencv_pdf'));
const Cvppt = lazy(() => import('./Components/PPTReader/CV_pdf '));
const Tensorflowppt = lazy(() => import('./Components/PPTReader/Tensorflow_pdf '));
const Pytorchppt = lazy(() => import('./Components/PPTReader/Pytorch_pdf '));
const Mlppt = lazy(() => import('./Components/PPTReader/ML_pdf '));
const Dlppt = lazy(() => import('./Components/PPTReader/DL_pdf'));
const Nlpppt = lazy(() => import('./Components/PPTReader/NLP_pdf '));
const Cnnppt = lazy(() => import('./Components/PPTReader/CNN_pdf '));
const Yoloppt = lazy(() => import('./Components/PPTReader/Yolo_pdf '));
const GenAIppt = lazy(() => import('./Components/PPTReader/Genai_pdf'));
const DSppt = lazy(() => import('./Components/PPTReader/Data_Science_pdf'));
const DAppt = lazy(() => import('./Components/PPTReader/Data_Analyst_pdf'));


// Projects
const Project1 = lazy(() => import('./Components/Projects/home_loan_approval'));
const Project2 = lazy(() => import('./Components/Projects/medical_Appointment_analytics'));
const Project3 = lazy(() => import('./Components/Projects/world_Happiness'));
const Project4 = lazy(() => import('./Components/Projects/Eda_streamlit_App'));
const Project5 = lazy(() => import('./Components/Projects/answering_business_questions_using_sql'));
const Project6 = lazy(() => import('./Components/Projects/imdb_movie_analytics'));
const Project7 = lazy(() => import('./Components/Projects/zomato_database_analytics'));
const Project8 = lazy(() => import('./Components/Projects/data_visualization_using_tableau'));
const Project9 = lazy(() => import('./Components/Projects/police_violence_in_usa'));
const Project10 = lazy(() => import('./Components/Projects/movies_Data_collection'));
const Project11 = lazy(() => import('./Components/Projects/fitbit-fitness-data-analysis'));
const Project12 = lazy(() => import('./Components/Projects/covid19_vaccination_tracking'));
const Project13 = lazy(() => import('./Components/Projects/diamond_price_prediction'));
const Project14 = lazy(() => import('./Components/Projects/NYC_Airbnb_prediction'));
const Project15 = lazy(() => import('./Components/Projects/Osteoporosis_Risk_Prediction'));
const Project16 = lazy(() => import('./Components/Projects/Titantic_survival_Prediction'));
const Project17 = lazy(() => import('./Components/Projects/diabetic-prediction'));
const Project18 = lazy(() => import('./Components/Projects/tensorflow_fashion_MNIST_classification'));
const Project19 = lazy(() => import('./Components/Projects/pytorch_fashion_MNIST_classification'));
const Project20 = lazy(() => import('./Components/Projects/mnist_digit_recognition'));
const Project21 = lazy(() => import('./Components/Projects/dogvscat_image_classifications'));
const Project22 = lazy(() => import('./Components/Projects/audio-classification'));
const Project23 = lazy(() => import('./Components/Projects/AI_Anime'));
const Project24 = lazy(() => import('./Components/Projects/Finding_Common_Trends'));
const Project25 = lazy(() => import('./Components/Projects/introtoAI_pets_project'));
const Project26 = lazy(() => import('./Components/Projects/illusion_diffusion'));
const Project27 = lazy(() => import('./Components/Projects/LLAVA_Demo'));
const Project28 = lazy(() => import('./Components/Projects/yolov7_training'));
const Project29 = lazy(() => import('./Components/Projects/Music_gen'));
const Project30 = lazy(() => import('./Components/Projects/OWL_ViT_inference_example'));
const Project31 = lazy(() => import('./Components/Projects/Fake_News_Classification_Using_NLP'));
const Project32 = lazy(() => import('./Components/Projects/Sentiment_Analysis_Complete'));
const Project33 = lazy(() => import('./Components/Projects/NLP_Toolbox'));
const Project34 = lazy(() => import('./Components/Projects/LangChain_Prompting_Basics_with_Mistral'));
const Project35 = lazy(() => import('./Components/Projects/chat_with_pdf_using_llama_v2'));
const Project36 = lazy(() => import('./Components/Projects/CV_Using_Mediapipe'));
const Project37 = lazy(() => import('./Components/Projects/deepface_facial_attribute_analytics'));
const Project38 = lazy(() => import('./Components/Projects/crowd_analytics_people_counting'))
const Project39 = lazy(() => import('./Components/Projects/real_time_face_expression_recognition'))
const Project40 = lazy(() => import('./Components/Projects/road_traffic_analytics'))
const Project41 = lazy(() => import('./Components/Projects/car_Lane_Detection_Development'))
const Project42 = lazy(() => import('./Components/Projects/receipt_data_extraction_using_ocr'))
const Project43 = lazy(() => import('./Components/Projects/Face_Blur_Using_OpenCV'))
const Project44 = lazy(() => import('./Components/Projects/gen-ai_llava_model'))
const Project45 = lazy(() => import('./Components/Projects/gen-ai_Stable_diffusion'))
const Project46 = lazy(() => import('./Components/Projects/Background_removal'))
const Project47 = lazy(() => import('./Components/Projects/multi_model_ai_bot'))
const Project48 = lazy(() => import('./Components/Projects/image_q_and_a_bot'))
const Project49 = lazy(() => import('./Components/Projects/motiongpt'))
const Project50 = lazy(() => import('./Components/Projects/gpt4'))
const Project51 = lazy(() => import('./Components/Projects/image_generation_and_editing'))
const Project52 = lazy(() => import('./Components/Projects/rag_framework_with_single_data'))
const Project53 = lazy(() => import('./Components/Projects/rag_framework_with_multiple_data'))
const Project54 = lazy(() => import('./Components/Projects/multimodal_rag_text_images'))
const Project55 = lazy(() => import('./Components/Projects/audio_video_text_image_multimodal_rag_opensource'))
const Project56 = lazy(() => import('./Components/Projects/crop_recommender'))
const Project57 = lazy(() => import('./Components/Projects/drug_recommender'))
const Project58 = lazy(() => import('./Components/Projects/Dogecoin_Price_Prediction'))
const Project59 = lazy(() => import('./Components/Projects/vehicle-price-detection'))
const Project60 = lazy(() => import('./Components/Projects/insurance-cost-prediction'))
const Project61 = lazy(() => import('./Components/Projects/rock-vs-mine-prediction'))
const Project62 = lazy(() => import('./Components/Projects/disease-diagnosis'))
const Project63 = lazy(() => import('./Components/Projects/credit-card-fraud-detection'))
const Project64 = lazy(() => import('./Components/Projects/market-customer-segmentation'))
const Project65 = lazy(() => import('./Components/Projects/gold-price-prediction'))
const Project66 = lazy(() => import('./Components/Projects/wine-quality-prediction'))
const Project67 = lazy(() => import('./Components/Projects/diabetic-prediction'))
const Project68 = lazy(() => import('./Components/Projects/Loan_status'))
const Project69 = lazy(() => import('./Components/Projects/parkinsons-disease-prediction'))
const Project70 = lazy(() => import('./Components/Projects/movie-recomendation-system'))
const Project71 = lazy(() => import('./Components/Projects/supermarket-sales-prediction'))
const Project72 = lazy(() => import('./Components/Projects/softdrinks-market-prediction'))
const Project73 = lazy(() => import('./Components/Modules/Ros1'))
const Project74 = lazy(() => import('./Components/Modules/Ros2'))
const Project75 = lazy(() => import('./Components/Projects/robotics1'))
const Project76 = lazy(() => import('./Components/Projects/robotics2'))
const Project77 = lazy(() => import('./Components/Projects/robotics3'))
const Project78 = lazy(() => import('./Components/Projects/robotics4'))
const Project79 = lazy(() => import('./Components/Projects/robotics5'))
const Project80 = lazy(() => import('./Components/Projects/sd-linear'))
const Project81 = lazy(() => import('./Components/Projects/sd-logistic'))
const Project82 = lazy(() => import('./Components/Projects/sd-kmean'))
const Project83 = lazy(() => import('./Components/Projects/sd-cnn'))
// const Project84 = lazy(() => import('./Components/Projects/'))
// const Project74 = lazy(() => import('./Components/Projects/'))
// const Project74 = lazy(() => import('./Components/Projects/'))
// const Project74 = lazy(() => import('./Components/Projects/'))
// const Project74 = lazy(() => import('./Components/Projects/'))





const routesConfig = [
  
  { path: '/login', element: <Login /> },
  { path: '/home', element: <><Header1 /><Home /></> },
  { path: '/Course', element: <Home /> },

  // Courses
  { path: '/Data_Analytics', element: <Course1 /> },
  { path: '/Data_Science', element: <Course2 /> },
  { path: '/Computer_Vision', element: <Course3 /> },
  { path: '/Software_development', element: <Course4 /> },
  { path: '/AI', element: <Course5 /> },
  { path: '/MachineLearning', element: <Course6 /> },
  { path: '/DL', element: <Course7 /> },
  { path: '/Gen_AI', element: <Course8 /> },
  { path: '/NLP', element: <Course9 /> },
  { path: '/Robotics', element: <Course10 /> },
  { path: '/Comprehensive_AI_Projects', element: <Course11 /> },

  // Modules
  { path: '/Linux', element: <Module2 /> },
  { path: '/Linux_Network', element: <Module3 /> },
  { path: '/Conda', element: <Module4 /> },
  { path: '/Jupyter', element: <Module5 /> },
  { path: '/Colab', element: <Module6 /> },
  { path: '/Vscode', element: <Module7 /> },
  { path: '/Kaggle', element: <Module8 /> },
  { path: '/Jetson', element: <Module9 /> },
  { path: '/Python', element: <Module10 /> },
  { path: '/Numpy', element: <Module11 /> },
  { path: '/Pandas', element: <Module12 /> },
  { path: '/Matplotlib', element: <Module13 /> },
  { path: '/Github', element: <Module14 /> },
  { path: '/Docker', element: <Module15 /> },
  { path: '/Opencv', element: <Module16 /> },
  { path: '/Cv', element: <Module17 /> },
  { path: '/Tensorflow', element: <Module18 /> },
  { path: '/Pytorch', element: <Module19 /> },
  { path: '/Ml', element: <Module20 /> },
  { path: '/Deeplearning', element: <Module21 /> },
  { path: '/Cnn', element: <Module22 /> },
  { path: '/nlp1', element: <Module23 /> },
  { path: '/Yolo', element: <Module24 /> },
  { path: '/Genai', element: <Module25 /> },
  { path: '/Data_Science1', element: <Module26 /> },
  { path: '/Data_Analytics1', element: <Module27 /> },

  // Projects
  { path: '/Interview', element: <Inter1 /> },
  { path: '/home_loan_approval', element: <Project1 /> },
  { path: '/medical_Appointment_analytics', element: <Project2 /> },
  { path: '/world_Happiness', element: <Project3 /> },
  { path: '/eda_Streamlit_App', element: <Project4 /> },
  { path: '/answering_business_questions_using_sql', element: <Project5 /> },
  { path: '/imdb_movie_analytics', element: <Project6 /> },
  { path: '/zomato_database_analytics', element: <Project7 /> },
  { path: '/data_visualization_using_tableau', element: <Project8 /> },
  { path: '/police_violence_in_usa', element: <Project9 /> },
  { path: '/movies_Data_collection', element: <Project10 /> },
  { path: '/fitbit-fitness-data-analysis', element: <Project11 /> },
  { path: '/covid19_vaccination_tracking', element: <Project12 /> },
  { path: '/diamond_price_prediction', element: <Project13 /> },
  { path: '/NYC_Airbnb_prediction', element: <Project14 /> },
  { path: '/Osteoporosis_Risk_Prediction', element: <Project15 /> },
  { path: '/Titantic_survival_Prediction', element: <Project16 /> },
  { path: '/Diabetes_Prediction', element: <Project17 /> },
  { path: '/tensorflow_fashion_MNIST_classification', element: <Project18 /> },
  { path: '/pytorch_fashion_MNIST_classification', element: <Project19 /> },
  { path: '/mnist_digit_recognition', element: <Project20 /> },
  { path: '/dogvscat_image_classifications', element: <Project21 /> },
  { path: '/audio-classification', element: <Project22 /> },
  { path: '/AI_Anime', element: <Project23 /> },
  { path: '/Finding_Common_Trends', element: <Project24 /> },
  { path: '/introtoAI_pets_project', element: <Project25 /> },
  { path: '/illusion_diffusion', element: <Project26 /> },
  { path: '/LLAVA_Demo', element: <Project27 /> },
  { path: '/yolov7_training', element: <Project28 /> },
  { path: '/Music_gen', element: <Project29 /> },
  { path: '/OWL_ViT_inference_example', element: <Project30 /> },
  { path: '/Fake_News_Classification_Using_NLP', element: <Project31 /> },
  { path: '/Sentiment_Analysis_Complete', element: <Project32 /> },
  { path: '/NLP_Toolbox', element: <Project33 /> },
  { path: '/LangChain_Prompting_Basics_with_Mistral', element: <Project34 /> },
  { path: '/chat_with_pdf_using_llama_v2', element: <Project35 /> },
  { path: '/cv_using_Mediapipe', element: <Project36 /> },
  { path: '/deepface_facial_attribute_analytics', element: <Project37 /> },
  { path: '/crowd_analytics_people_counting', element: <Project38 /> },
  { path: '/real_time_face_expression_recognition', element: <Project39 /> },
  { path: '/road_traffic_analytics', element: <Project40 /> },
  { path: '/car_Lane_Detection_Development', element: <Project41 /> },
  { path: '/receipt_data_extraction_using_ocr', element: <Project42 /> },
  { path: '/Face_Blur_Using_OpenCV', element: <Project43 /> },
  { path: '/gen-ai_llava_model', element: <Project44 /> },
  { path: '/gen-ai_Stable_diffusion', element: <Project45 /> },
  { path: '/Background_removal', element: <Project46 /> },
  { path: '/multi_model_ai_bot', element: <Project47 /> },
  { path: '/image_q_and_a_bot', element: <Project48 /> },
  { path: '/motiongpt', element: <Project49 /> },
  { path: '/gpt4', element: <Project50 /> },
  { path: '/image_generation_and_editing', element: <Project51 /> },
  { path: '/rag_framework_with_single_data', element: <Project52 /> },
  { path: '/rag_framework_with_multiple_data', element: <Project53 /> },
  { path: '/multimodal_rag_text_images', element: <Project54 /> },
  { path: '/audio_video_text_image_multimodal_rag_opensource', element: <Project55 /> },
  { path: '/crop_recommender', element: <Project56 /> },
  { path: '/drug_recommender', element: <Project57 /> },
  { path: '/Dogecoin_Price_Prediction', element: <Project58 /> },
  { path: '/vehicle-price-detection', element: <Project59 /> },
  { path: '/insurance-cost-prediction', element: <Project60 /> },
  { path: '/rock-vs-mine-prediction', element: <Project61 /> },
  { path: '/disease-diagnosis', element: <Project62 /> },
  { path: '/credit-card-fraud-detection', element: <Project63 /> },
  { path: '/market-customer-segmentation', element: <Project64 /> },
  { path: '/gold-price-prediction', element: <Project65 /> },
  { path: '/wine-quality-prediction', element: <Project66 /> },
  { path: '/diabetic-prediction', element: <Project67 /> },
  { path: '/Loan_status', element: <Project68 /> },
  { path: '/parkinsons-disease-prediction', element: <Project69 /> },
  { path: '/movie-recomendation-system', element: <Project70 /> },
  { path: '/supermarket-sales-prediction', element: <Project71 /> },
  { path: '/softdrinks-market-prediction', element: <Project72 /> },
  { path: '/Ros1', element: <Project73 /> },
  { path: '/Ros2', element: <Project74 /> },
  { path: '/robotics1', element: <Project75 /> },
  { path: '/robotics2', element: <Project76 /> },
  { path: '/robotics3', element: <Project77 /> },
  { path: '/robotics4', element: <Project78 /> },
  { path: '/robotics5', element: <Project79 /> },
  { path: '/sd-linear', element: <Project80 /> },
  { path: '/sd-logistic', element: <Project81 /> },
  { path: '/sd-kmean', element: <Project82 /> },
  { path: '/sd-cnn', element: <Project83 /> },
  // { path: '/', element: <Project74 /> },
  // { path: '/', element: <Project74 /> },
  // { path: '/', element: <Project74 /> },
  // { path: '/', element: <Project74 /> },
  // { path: '/', element: <Project74 /> },
  // { path: '/', element: <Project74 /> },




  // Lessons
  // { path: '/AI_Intro/lesson/0', element: <AIIntro />},
  { path: '/linux/lesson/0', element: <Linux /> },
  { path: '/linux_network/lesson/0', element: <LN /> },
  { path: '/Jetson/lesson/0', element: <Jetson /> },
  { path: '/conda/lesson/0', element: <Conda /> },
  { path: '/jupyter/lesson/0', element: <Jupyter /> },
  { path: '/python/lesson/0', element: <Python /> },
  { path: '/numpy/lesson/0', element: <Numpy /> },
  { path: '/pandas/lesson/0', element: <Pandas /> },
  { path: '/matplotlib/lesson/0', element: <Matplotlib /> },
  { path: '/colab/lesson/0', element: <Colab /> },
  { path: '/kaggle/lesson/0', element: <Kaggle /> },
  { path: '/vscode/lesson/0', element: <VSCode /> },
  { path: '/github/lesson/0', element: <Github /> },
  { path: '/docker/lesson/0', element: <Docker /> },
  { path: '/opencv/lesson/0', element: <OpenCv /> },
  { path: '/cv/lesson/0', element: <Cv /> },
  { path: '/tensorflow/lesson/0', element: <Tensorflow /> },
  { path: '/pytorch/lesson/0', element: <Pytorch /> },
  { path: '/ml/lesson/0', element: <Ml /> },
  { path: '/dl/lesson/0', element: <Dl /> },
  { path: '/nlp/lesson/0', element: <Nlp /> },
  { path: '/cnn/lesson/0', element: <Cnn /> },
  { path: '/yolo/lesson/0', element: <Yolo /> },
  { path: '/genai/lesson/0', element: <GenAI /> },
  { path: '/Data_Science/lesson/0', element: <DataScience /> },
  { path: '/Data_Analytics/lesson/0', element: <DataAnalytics /> },


  // { path: '/AI_Intro/lesson/1', element: <AIIntropdf /> },
  { path: '/linux/lesson/1', element: <Linuxpdf /> },
  { path: '/linux_network/lesson/1', element: <LinuxNetworkpdf /> },
  { path: '/Jetson/lesson/1', element: <Jetsonpdf /> },
  { path: '/conda/lesson/1', element: <Condapdf /> },
  { path: '/jupyter/lesson/1', element: <Jupyterpdf /> },
  { path: '/python/lesson/1', element: <Pythonpdf /> },
  { path: '/numpy/lesson/1', element: <Numpypdf /> },
  { path: '/pandas/lesson/1', element: <Pandaspdf /> },
  { path: '/matplotlib/lesson/1', element: <Matplotlibpdf /> },
  { path: '/colab/lesson/1', element: <Colabpdf /> },
  { path: '/kaggle/lesson/1', element: <Kagglepdf /> },
  { path: '/vscode/lesson/1', element: <VSCodepdf /> },
  { path: '/github/lesson/1', element: <Githubpdf /> },
  { path: '/docker/lesson/1', element: <Dockerpdf /> },
  { path: '/opencv/lesson/1', element: <OpenCvpdf /> },
  { path: '/cv/lesson/1', element: <Cvpdf /> },
  { path: '/tensorflow/lesson/1', element: <Tensorflowpdf /> },
  { path: '/pytorch/lesson/1', element: <Pytorchpdf /> },
  { path: '/ml/lesson/1', element: <Mlpdf /> },
  { path: '/dl/lesson/1', element: <Dlpdf /> },
  { path: '/nlp/lesson/1', element: <Nlppdf /> },
  { path: '/cnn/lesson/1', element: <Cnnpdf /> },
  { path: '/yolo/lesson/1', element: <Yolopdf /> },
  { path: '/genai/lesson/1', element: <GenAIpdf /> },
  { path: '/Data_Science/lesson/1', element: <DSpdf /> },
  { path: '/Data_Analytics/lesson/1', element: <DApdf /> },



  // { path: '/AI_Intro/lesson/2', element: <AIIntroppt /> },
  { path: '/linux/lesson/2', element: <Linuxppt /> },
  { path: '/linux_network/lesson/2', element: <LinuxNetworkppt /> },
  { path: '/Jetson/lesson/2', element: <Jetsonppt /> },
  { path: '/conda/lesson/2', element: <Condappt /> },
  { path: '/jupyter/lesson/2', element: <Jupyterppt /> },
  { path: '/python/lesson/2', element: <Pythonppt /> },
  { path: '/numpy/lesson/2', element: <Numpyppt /> },
  { path: '/pandas/lesson/2', element: <Pandasppt /> },
  { path: '/matplotlib/lesson/2', element: <Matplotlibppt /> },
  { path: '/colab/lesson/2', element: <Colabppt /> },
  { path: '/kaggle/lesson/2', element: <Kaggleppt /> },
  { path: '/vscode/lesson/2', element: <VSCodeppt /> },
  { path: '/github/lesson/2', element: <Githubppt /> },
  { path: '/docker/lesson/2', element: <Dockerppt /> },
  { path: '/opencv/lesson/2', element: <OpenCvppt /> },
  { path: '/cv/lesson/2', element: <Cvppt /> },
  { path: '/tensorflow/lesson/2', element: <Tensorflowppt /> },
  { path: '/pytorch/lesson/2', element: <Pytorchppt /> },
  { path: '/ml/lesson/2', element: <Mlppt /> },
  { path: '/dl/lesson/2', element: <Dlppt /> },
  { path: '/nlp/lesson/2', element: <Nlpppt /> },
  { path: '/cnn/lesson/2', element: <Cnnppt /> },
  { path: '/yolo/lesson/2', element: <Yoloppt /> },
  { path: '/genai/lesson/2', element: <GenAIppt /> },
  { path: '/Data_Analytics/lesson/2', element: <DAppt /> },
  { path: '/Data_Science/lesson/2', element: <DSppt /> },


  // Interview

  { path: '/AI_Intro', element: <AIintroInterview /> },
  { path: '/CNN_Interview', element: <CnnInterview /> },
  { path: '/Conda_Interview', element: <CondaInterview /> },
  { path: '/Colab_Interview', element: <ColabInterview /> },
  { path: '/CV_Interview', element: <CvInterview /> },
  { path: '/DA_Interview', element: <DAInterview /> },
  { path: '/DS_Interview', element: <DSInterview /> },
  { path: '/Dl_Interview', element: <DlInterview /> },
  { path: '/Docker_Interview', element: <DockerInterview /> },
  { path: '/GenAI_Interview', element: <GenaiInterview /> },
  { path: '/Github_Interview', element: <GithubInterview /> },
  { path: '/Jupyter_Interview', element: <JupyterInterview /> },
  { path: '/Kaggle_Interview', element: <KaggleInterview /> },
  { path: '/Linux_Interview', element: <LinuxInterview /> },
  { path: '/linux_Networking_Interview', element: <LinuxNetworkingInterview /> },
  { path: '/Ml_Interview', element: <MlInterview /> },
  { path: '/Matplotlib_Interview', element: <MatplotlibInterview /> },
  { path: '/NLP_Interview', element: <NlpInterview /> },
  { path: '/Numpy_Interview', element: <NumpyInterview /> },
  { path: '/Opencv_Interview', element: <OpenCVInterview /> },
  { path: '/Pandas_Interview', element: <PandasInterview /> },
  { path: '/Python_Interview', element: <PythonInterview /> },
  { path: '/Pytorch_Interview', element: <PytorchInterview /> },
  { path: '/Tensorflow_Interview', element: <TensorflowInterview /> },
  { path: '/Vscode_Interview', element: <VscodeInterview /> },
  { path: '/Yolo_Interview', element: <YoloInterview /> },

];

export default routesConfig;
