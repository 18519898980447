import React from 'react'
import './About.css'

import vid from '../AssetsMain/Videos/Deepcept_AI.mp4'

const title = "About Us"

const description = [
    {
        desc:
            'Deepcept AI is an AI product development and software service startup focused on building AI applications and solutions which are custom tailored to your requirements.',
    },
    {
        desc:
        'Deepcept also provides necessary consultation and upskilling in the latest AI/ML technologies for IT industries and academic institutions.',
    },
    {
        desc:
        'We are committed to bridging the gap between Academia and Industry for AI and its related fields through our practical hands-on internship programs. ',
    }
]

function About() {
  return (
    <div id='about' className='about-container'>
        <h1 className='about-title'>{title}</h1>
        <div className='abt-cont'>
            <div className='abt-left'>
                {description.map( desc => (
                            <div className='about-desc'>
                                {desc.desc}
                                <br></br><br></br>
                            </div>
                ))}
            </div>
            <div className='abt-right'>
                <video className='abt-vid' controls>
                    <source src={vid} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    </div>
  )
}

export default About
