import React from 'react';
import './Products.css';

import product_img from '../AssetsMain/Images/surveillance.gif';
import services_img from '../AssetsMain/Images/retail_analytics_2.gif';
import internships_img from '../AssetsMain/Images/smart_city.gif';

const Offers = [
    {
        id: 1,
        title: 'AI Video Analytics Platform',
        vid: product_img,
        description: [
            'Designed using the latest Machine Learning and Deep Learning AI technologies.',
            'Transforms your raw video into actionable data.',
            'Incident detection and reporting along with real-time notifications to phone.',
            'Integrated with Amazon Alexa for remote voice-based control.'
        ]
    },
    {
        id: 2,
        title: 'Retail analytics Platform',
        vid: services_img,
        description: [
            'Increase your store\'s productivity using AI for customer interactions and inventory management.',
            'Prevent shoplifting and analyze customer behaviour.',
            'Save costs on the number of item procurements.',
            'Optimize your item placement and reduce replenishment time.'
        ]
    },
    {
        id: 3,
        title: 'Smart city applications Platform',
        vid: internships_img,
        description: [
            'Combine vision AI and IOT on the edge or on the cloud to address traffic management.',
            'Detect violations and track compliances using Deepcept\'s platform.',
            'Keep track of the number and type of vehicles along with traffic heatmaps.',
            'Manage traffic to address emergency vehicle flow in advance.',
            'Transition towards intelligent connected ecosystems.'
        ]
    }
];

function Home2() { 
    return (
        <section id='products' className='solution-container'>
            <div className='solution-left-side'>
                <h1 className='prod-main-title'>Our Products</h1>
                {Offers.map(offer => (
                    <div className='solution-box' key={offer.id}>
                        <div className='solution-box-b'>
                            <img className='prod-vid' src={offer.vid} alt={offer.title} />
                        </div>
                        <div className='solution-description'>
                            <h1 className='prod-title'>{offer.title}</h1>
                            <ul className='prod-desc'>
                                {offer.description.map((point, index) => (
                                    <li key={index}>
                                        {point}
                                        <br></br>
                                        <br></br>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Home2;
