import React, { useState } from "react";
import FluidAnimation from "@usertive/react-fluid-animation";
import { useRef } from "react";
import "../Fluid/Fluid.css";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Spinner } from "react-bootstrap";
import { useUserAuth } from "./UserAuthContext";
// import { Link } from 'react-router-dom';
import './Login.css'; // Make sure to import the CSS file

const defaultConfig = {
  textureDownsample: 1,
  densityDissipation: 0.98,
  velocityDissipation: 0.99,
  pressureDissipation: 0.8,
  pressureIterations: 25,
  curl: 30,
  splatRadius: 0.005,
  colorsPool: ['#FF1100', '#FF0046', '#5D00FF', '#0043FF', '#0088FF', '#00DCFF', '#00FFF7', '#00FFD4', '#00FFA2', '#DADADA', '#AADDAA'],
};

const useForm = (initialState, submitCallback) => {
  const [values, setValues] = useState(initialState);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    try {
      await submitCallback(values);
    } catch (err) {
      if (err.message.includes("auth/invalid-email")) {
        setError("The email address is not valid. Please enter a valid email.");
      } else if (err.message.includes("Login limit reached")) {
        setError("Your login limit has been reached. Please contact support.");
      } else {
        setError("Invalid user! Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return { values, handleChange, handleSubmit, error, loading };
};

const Login = () => {
  const [config] = useState({ ...defaultConfig });
  const animationRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const { logIn } = useUserAuth();
  const { values, handleChange, handleSubmit, error, loading } = useForm(
    { email: "", password: "" },
    async ({ email, password }) => {
      await logIn(email, password);
      navigate("/home");
    }
  );

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRedirect = (route) => {
      navigate(route);
  };

  return (
    <div className="login-container">
      <FluidAnimation
        className="login-fluid-background"
        config={config}
        animationRef={(animation) => animationRef.current = animation}
      />
      <div className="login-gradient-text-h1">
        <h1 className="login-title">LOGIN</h1>
        {error && <Alert variant="danger" className="alert-danger">{error}</Alert>}
        <form onSubmit={handleSubmit}>
          <label className="form-label">UserName:</label>
          <input
            type="email"
            name="email"
            placeholder="User Name"
            value={values.email}
            onChange={handleChange}
            className="login-input"
            autoComplete="off"
            required
          />
          <label className="form-label">Password:</label>
          <div className="password-container">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              value={values.password}
              onChange={handleChange}
              className="login-input"
              autoComplete="new-password"
              required
            />
            <button
              type="button"
              className="password-toggle-btn"
              onClick={togglePasswordVisibility}
            >
              <i className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}></i>
            </button>
          </div>
          <Button
            variant="primary"
            type="submit"
            className="login-button"
            disabled={loading}
          >
            {loading && <Spinner as="span" animation="border" size="sm" className="login-spinner" />}
            Login
          </Button>
          <h3>Not yet Enrolled! Click the below button to view</h3>
            <Button className="login-button" onClick={() => handleRedirect('/internship')}>
              Click to Enroll
            </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
