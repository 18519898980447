import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import { ReactTyped } from 'react-typed';

function Home() {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();


  const handleRedirect1 = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer'); // Opens the URL in a new tab
  };


  const handleRedirect = (id) => {
    navigate(id); // Navigate to the login page
  };


  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    
    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <section id='home' className={`home-container-main ${isMobile ? 'mobile-background' : ''}`}>
      <div className='left-side'>
        <h1 className='comp-title'>DEEPCEPT AI</h1>
        <div>
            <h1 className="comp-title1">
            Complex AI integration {' '}
            <ReactTyped className='typed'
              loop
              typeSpeed={100}
              backSpeed={70}
              strings={['made Seamless.', 'made Reliable.', 'made Robust.', 'made Effortless.']}
              shuffle={false}
              backDelay={400}
              fadeOut={false}
              fadeOutDelay={100}
              loopCount={100000}
              showCursor
              cursorChar=""
            />
          </h1>
        </div>
        <div>
            <h1 className="comp-title1">
            {' '}
            <ReactTyped className='typed1'
              loop
              typeSpeed={30}
              backSpeed={80}
              strings={['Unlock limitless opportunities with Deepcept AI.', 'Gain hands-on experience - 100+ industry projects.', 'Train, Build and Deploy your future in AI.']}
              shuffle={false}
              backDelay={400}
              fadeOut={false}
              fadeOutDelay={100}
              loopCount={100000}
              showCursor
              cursorChar=""
            />
          </h1>
        </div>
        <button className='internship-btn' onClick={() => handleRedirect('/internship')}>Know More About Internship</button>
        <button className='demo-btn' onClick={() => handleRedirect1('https://demo-deepcept.netlify.app')}>View Demo</button>
      </div>
    </section>
  );
}

export default Home;
