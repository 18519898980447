import React, { useState } from "react";
import './Testimonial.css'; 

import img1 from '../AssetsMain/Images/Male_testimonials.jpg'
import img2 from '../AssetsMain/Images/female_testimonial.png'

const testimonials = [
  {
    text: [
          "I had a fantastic internship at Deepcept AI and worked on the development of the No Code EDA platform project.",
          "The project itself was from the product development perspective which helped me gain industry experience.",
          "The environment is supportive and encourages trying new things, approaches, and technologies.", 
          "The internship also allowed me to publish a white paper on the project.",
        ],
    name: "Arnav Sharma",
    role: "B.Tech CSE, Nirma University",
    image: img1
  },
  {
    text: [
          "I had an opportunity to work on the latest AI concepts such as action recognition during my internship at Deepcept AI while I was in my 3rd year of B.Tech.",
          "I learnt a lot on industry-grade AI development on big AI workstations and how it gets turned into a product on edge AI devices.",
          "I was allowed to handle expensive edge AI equipment as well.",
        ],
    name: "Rahul Jangra",
    role: "B.Tech CSE, MVIT",
    image: img1
  },
  {
    text: [ 
          "My internship experience at Deepcept AI allowed me to work on the latest facial attribute technologies as a part of my project and apply them on edge hardware such as TPUs and GPUs.",
          "This allowed me to get industry-level experience on product development and deployment.",
          "My project also involved not only Python but also a full stack of frameworks, exactly how industries operate.",
        ],
    name: "Laasya Reddy",
    role: "B.Tech AI, BNMIT",
    image: img2
  },
  {
    text: [
          "The internship opportunity at Deepcept AI was exhilarating where I developed an ML platform for data analytics which also received great attention during its presentation at my university.",
          " The supportive mentorship helped me enhance technical skills and industry experience.",
          "I was also fortunate to publish a paper on my project which highly improved my CV.",
        ],
    name: "Aryan Desai",
    role: "B.Tech CSE, Nirma University",
    image: img1
  }
];


export default function TestimonialCarousel() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsToShow = 3; // Number of testimonials to display

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % (testimonials.length - itemsToShow + 1));
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + (testimonials.length - itemsToShow + 1)) % (testimonials.length - itemsToShow + 1));
  };

  // Calculate the testimonials to display
  const displayedTestimonials = testimonials.slice(currentIndex, currentIndex + itemsToShow);

  return (
    <div className="carousel-container">
      <h1>Testimonials</h1>
      <div className="carousel-content">
        <button className="carousel-control prev" onClick={prevTestimonial}>❮</button>
        <div className="desc">
          {displayedTestimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-item">
              <div className="im_na">
                <img src={testimonial.image} alt={testimonial.name} />
                <div className="lli">
                  <li className="image-caption">{testimonial.name}</li>
                  <li className="image-caption">{testimonial.role}</li>
                </div>
              </div>
              <div className="testimonial-list">
                {testimonial.text.map((item, index) => (
                  <p key={index} className="test-desc">
                    {item}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
        <button className="carousel-control next" onClick={nextTestimonial}>❯</button>
      </div>
    </div>
  );
}
