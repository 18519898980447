import React from 'react'
import './Ydeep.css'

const why_list = [
  {
      "title": "Budget Friendly",
      "desc": "We offer an affordable service and solutions that ensure you receive great value for your investment in us."
  },
  {
      "title": "Professional Expert Team",
      "desc": "Our specialists are industry professionals with an average of 20+ years of experience and are updated with the latest technologies."
  },
  {
      "title": "Commitment To Customers",
      "desc": "We deliver our commitments. Those guided by us have achieved positive outcomes in their careers. Invest your time with us, and we'll help you build your career."
  },
  {
      "title": "Creative Environment",
      "desc": "We are highly innovative in our end-to-end services. We follow a startup work culture that encourages creativity and value addition."
  },
  {
      "title": "Up-To-Date With Latest Technology",
      "desc": "We keep updating our solutions and content with the latest effective technologies and methods. We are dynamic and research-friendly."
  },
  {
      "title": "Industry Ready Launchpad",
      "desc": "Working or interning with us will transform you to be industry ready both technically and non-technically."
  }
];


function Ydeep() {
  return (
    <div className='ydeep-container'>
      <h1 className='ydeep-title'>WHY CHOOSE DEEPCEPTAI</h1>
      <div className='ydeep-cont'>
        {why_list.map (why => (
          <div className='ydeep-box'>
            <h1 className='ydeep-sub-title'>{why.title}</h1>
            <p className='ydeep-desc'>{why.desc}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Ydeep
