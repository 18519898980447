import FluidAnimation from "@usertive/react-fluid-animation";
import { useRef, useState } from "react";
import "./Fluid.css";
import Scroll from './Scroll'

const defaultConfig = {
  textureDownsample: 1,
  densityDissipation: 0.98,
  velocityDissipation: 0.99,
  pressureDissipation: 0.8,
  pressureIterations: 25,
  curl: 30,
  splatRadius: 0.005,
  colorsPool: ['#FF1100', '#FF0046', '#5D00FF', '#0043FF', '#0088FF', '#00DCFF', '#00FFF7', '#00FFD4', '#00FFA2', '#DADADA', '#AADDAA'],
};

export default function Fluid() {
  const [config] = useState({ ...defaultConfig });
  const animationRef = useRef(null);

  return (
    <div className='fluid-container'>
      <FluidAnimation
        className='fluid-background'
        config={config}
        animationRef={(animation) => animationRef.current = animation}
      />
        <h1 className="gradient-text-h1">
          We help you to Train, Build <br />
          and Deploy yourself into the AI world<br />
        </h1>
        <Scroll />
    </div>
  );
}
