import React, { useState } from 'react';
import './Header.css'
// import { useNavigate } from 'react-router-dom'
import Logo from '../Assets/Images/Deepcept.svg';



function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    // const navigate = useNavigate();


    const handleMenuToggle = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    // const handleRedirect = (route) => {
    //     navigate(route); // Navigate to the login page
    // };
  
  return (
    <div className='header-container'>
        <a href='/' className='header-logo'>
        <img className='App-logo' src={Logo} alt="DeepceptAI" />
        </a>
        <div className='hamburger' onClick={handleMenuToggle}>
          ☰
        </div>
        <div className={`mode ${isMenuOpen ? 'open' : ''}`}>
            {/* <div className='nav-content'>
                <ul>
                    <li>
                            <button className='login-btn' onClick={() => handleRedirect('/contact')}>
                                ENROLL
                            </button>
                    </li>
                    <li>
                            <button className='login-btn' onClick={() => handleRedirect('/login')}>
                                LOGIN
                            </button>
                    </li>
                </ul>
            </div> */}
        </div>
    </div>
  )
}

export default Header
