import React from 'react'
import './Service.css'

const Services_list = [
  {
    title: 'AI Software Development',
    desc: [
      'Tailored AI Solutions: Customized applications for your unique business needs.',
      'Complete Development Cycle: From consultation to ongoing support.',
      'Industry Expertise: Proven success across healthcare, finance, and retail.'
    ]
  },
  {
    title: 'AI Model Training',
    desc: [
      'Personalized AI Model Training: Tailored models to fit your specific data.',
      'Data Optimization: Enhance performance with quality data preprocessing.',
      'Continuous Improvement: Ongoing updates for evolving business requirements.'
    ]
  },
  {
    title: 'Custom RAG Framework',
    desc: [
      'Tailored Frameworks: Customized RAG solutions for your business.',
      'No-Code Platform: Adapt to business data changes on the fly.',
      'Scalable Integration: Easily adaptable to your existing systems.'
    ]
  },
  {
    title: 'Practical AI Internship',
    desc: [
      'Hands-On Experience: Real-world projects to build essential skills.',
      'Mentorship Program: Guidance from industry professionals.',
      'Flexible Learning: Customized schedules to fit your needs.'
    ]
  },
  {
    title: 'Corporate Upskilling',
    desc: [
      'Targeted Programs: Tailored upskilling to enhance employee skills.',
      'Industry-Relevant Content: Designed for current market demands.',
      'Flexible Delivery: Online and in-person options available.'
    ]
  },
  {
    title: 'AI Lab Facility',
    desc: [
      'State-of-the-Art Resources: Access to advanced AI tools and technologies.',
      'Collaborative Space: Foster innovation through teamwork and networking.',
      'Hands-On Learning: Practical experiments to enhance AI knowledge.'
    ]
  },
]

function Services() {
  return (
    <div id='service' className='service-container'>
      <h1 className='service-title'>Services</h1>
      <div className='service-cont'>
        {Services_list.map((Service, index) => (
          <div key={index} className='service-box'>
            <h1 className='service-sub'>{Service.title}</h1>
            <div className='service-desc'>
              {Service.desc.map((des, descIndex) => (
                <li key={descIndex}>
                  {des}
                  <br></br>
                  <br></br>
                </li>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Services
