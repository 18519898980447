import React from 'react';
import './Scroll.css';

const Chevron = () => {
  return (
    <div className="scrl-container">
      <div className="chevron"></div>
      <div className="chevron"></div>
      <div className="chevron"></div>
    </div>
  );
};

export default Chevron;
