import React from 'react'
import './Footer.css'

function Footer() {
  return (
    <div className='footer-container'>
      <h4 className='footer-rights'>ALL RIGHTS RESERVED © Deepcept AI 2024</h4>
    </div>
  )
}

export default Footer
