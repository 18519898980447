import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyB09dvBfn3e7SaUOAkom8CqbVV_jcKLKVE",
  authDomain: "deepcept-internship-testing.firebaseapp.com",
  databaseURL: "https://deepcept-internship-testing-default-rtdb.firebaseio.com",
  projectId: "deepcept-internship-testing",
  storageBucket: "deepcept-internship-testing.appspot.com",
  messagingSenderId: "660495181852",
  appId: "1:660495181852:web:f1ae22e9baecb1de8b57d9",
  measurementId: "G-8WSEDWXK1N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getDatabase(app);
export default app;
