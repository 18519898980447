import React from 'react';
import pdfFile from '../PDFs/edge_lpr_pipeline.pdf'; // Ensure the path is correct

const PdfViewer = () => {
  return (
    <div style={{ width: '100%', height: '100vh' }}>
\
      <object
        data={pdfFile}
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <p>It appears you don't have a PDF plugin for this browser. No biggie... you can <a href={pdfFile} target="_blank" rel="noopener noreferrer">click here to download the PDF file.</a></p>
      </object>
    </div>
  );
};

export default PdfViewer;