import { createContext, useContext, useEffect, useState } from "react";
import { signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import { ref, get, update } from "firebase/database";
import { auth, db } from "./firebase"; // Import the Firebase configuration

const userAuthContext = createContext();

// Fetch token_timer from Firebase
const fetchTokenTimer = async () => {
  const tokenTimerRef = ref(db, 'token_timer');
  const snapshot = await get(tokenTimerRef);
  if (snapshot.exists()) {
    return snapshot.val(); // Return the value of token_timer
  } else {
    console.error('No token_timer found');
    return 5; // Default to 5 minutes if no value is found
  }
};

// Generate a Base64 encoded token
const generateToken = async (userData) => {
  const tokenTimer = await fetchTokenTimer();

  const payload = {
    email: userData.email,
    course: userData.course,
    createdAt: Date.now(),
    exp: Date.now() + tokenTimer * 60 * 1000 // Token expiration based on token_timer
  };

  // Properly encode the payload
  const jsonPayload = JSON.stringify(payload);
  return btoa(unescape(encodeURIComponent(jsonPayload))); // Encode and return
};

// Decode a Base64 token
const decodeToken = (token) => {
  if (!token) {
    console.error('Token is empty or undefined.');
    return null;
  }

  try {
    const decoded = atob(token);
    return JSON.parse(decoded);
  } catch (error) {
    console.error('Failed to decode token:', error);
    return null;
  }
};

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState(null); // Initialize user state
  const [loading, setLoading] = useState(true); // Loading state to handle async checks

  // Function to check token validity and handle logout if necessary
  const checkTokenValidity = async () => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      const decodedToken = decodeToken(storedToken);
      // Check if the decoded token is valid
      if (decodedToken && decodedToken.exp <= Date.now()) {
        await logOut(); // Token has expired
        alert("Your session has expired. Please log in again.");
      }
    } else {
      console.warn('No token found in local storage.'); // Log a warning if no token is found
    }
  };

  // Log in function with database check
  async function logIn(email, password) {
    const userKey = email.replace(/\./g, '_'); // Convert email to a valid path

    try {
      setLoading(true); // Start loading while checking the database

      const snapshot = await get(ref(db, `users/${userKey}`));
      
      if (snapshot.exists()) {
        const userData = snapshot.val();

        // Check if loginCount has reached the limit
        if (userData.loginCount >= userData.loginLimit) {
          throw new Error("Login limit reached. Please contact support.");
        }

        // Validate password with Firebase Authentication
        await signInWithEmailAndPassword(auth, email, password);

        // Check if the user is already logged in within the same browser
        const browserSessionKey = `user_session_${userKey}`;
        if (!localStorage.getItem(browserSessionKey)) {
          // First login in this browser, increment loginCount
          await update(ref(db, `users/${userKey}`), {
            loginCount: userData.loginCount + 1,
            loginStatus: 1, // Set login status as logged in
          });
          
          // Store a session key in localStorage to indicate the user is logged in
          localStorage.setItem(browserSessionKey, 'active');
        }

        // Generate the token
        const token = await generateToken({
          email: userData.email,
          course: userData.course,
        });

        // Set the user in the state and local storage
        const loggedInUser = {
          email: userData.email,
          course: userData.course,
          token,
        };

        setUser(loggedInUser);
        localStorage.setItem('user', JSON.stringify(loggedInUser));
        localStorage.setItem('token', token); // Store the token in local storage
      } else {
        throw new Error("User not found. Please check your email and try again.");
      }
    } catch (error) {
      alert(error.message); // Notify the user about the error
    } finally {
      setLoading(false); // End loading after login
    }
  }

  // Log out function
  async function logOut() {
    const currentUser = auth.currentUser;

    if (currentUser) {
      const email = currentUser.email;
      const userKey = email.replace(/\./g, '_');
      const browserSessionKey = `user_session_${userKey}`;

      // Decrement the login count only if this is the last active tab in the browser
      if (localStorage.getItem(browserSessionKey)) {
        await update(ref(db, `users/${userKey}`), {
          loginCount: Math.max(0, (await get(ref(db, `users/${userKey}/loginCount`))).val() - 1),
          loginStatus: 0, // Set login status as logged out
        });

        // Remove the session key from localStorage
        localStorage.removeItem(browserSessionKey);
      }

      // Perform Firebase sign out
      await signOut(auth);

      // Clear user from state and local storage
      setUser(null);
      localStorage.removeItem('user');
      localStorage.removeItem('token'); // Clear the token on logout

      // Notify other tabs in the same browser about the logout
      localStorage.setItem('logout', Date.now());
    }
  }

  // Authentication state listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const storedToken = localStorage.getItem('token');

        if (storedUser && storedToken) {
          const decodedToken = decodeToken(storedToken);
          if (decodedToken && decodedToken.exp > Date.now()) {
            setUser(storedUser); // Update user if storedUser exists and token is valid
          } else {
            logOut(); // Log out if the token has expired
            alert("Your session has expired. Please log in again.");
          }
        }
      }
      setLoading(false); // Stop loading once auth state is determined
    });

    // Check token validity at regular intervals
    const tokenCheckInterval = setInterval(checkTokenValidity, 60 * 1000); // Check every minute

    // Listen for logout events from other tabs
    const handleStorageChange = (event) => {
      if (event.key === 'logout') {
        logOut(); // Log out if another tab sets the logout key
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      clearInterval(tokenCheckInterval); // Clear interval on unmount
      unsubscribe();
      window.removeEventListener('storage', handleStorageChange); // Clean up event listener
    };
  }, []);

  return (
    <userAuthContext.Provider value={{ user, logIn, logOut, loading }}>
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
