import React /*{ useEffect, useRef }*/ from 'react';
import Testimonial from '../Testimonial/Testimonial'
// import { useNavigate } from 'react-router-dom';
import Header2 from '../HeaderMain/Header2'
// import Fluid from '../Fluid/Fluid'
import './Internship.css'

import img1 from '../AssetsMain/Images/work.png'
import img2 from '../AssetsMain/Images/cloud.png'
import img3 from '../AssetsMain/Images/edge.png'
import img4 from '../AssetsMain/Images/interview.png'


const Internship_list = [
  {
    title: 'Data Analytics',
    desc: '10+ Data Analytics Modules. Multiple industry oriented and real world projects',
    sub: 'Technologies covered:',
    points: [
      'Python for data analytics',
      'AI for data analytics',
      'SQL, Tableau, BI tools',
      'Analytical libraries',
      'Data analytics',
    ]
  },
  {
    title: 'Data Science',
    desc: '15+ data science modules. Multiple industry oriented and real world projects.',
    sub: 'Technologies covered:',
    points: [
      'Python for data science',
      'AI for data science',
      'Machine Learning, TensorFlow, Scikit-learn',
      'Keras, PyTorch',
      'Applied data science modules',
    ]
  },
  {
    title: 'Machine Learning',
    desc: '15+ machine learning modules. Multiple industry oriented and real world projects.',
    sub: 'Technologies covered:',
    points: [
      'Machine Learning, Applied ML algorithms',
      'Python for machine learning',
      'TensorFlow, Keras, Scikit-learn',
      'PyTorch, Gradio',
    ]
  },
  {
    title: 'Deep Learning',
    desc: '15+ deep learning modules. Multiple industry oriented and real world projects.',
    sub: 'Technologies covered:',
    points: [
      'Deep learning, Applied DL algorithms',
      'PyTorch, TensorFlow, CNN',
      'Python for deep learning',
      'Computer vision, OpenCV',
      'Kaggle, Lightning',
    ]
  },
  {
    title: 'Artificial Intelligence',
    desc: '20+ AI modules. Multiple industry oriented and real world projects.',
    sub: 'Technologies covered:',
    points: [
      'Machine Learning, Deep Learning, AI',
      'Python for AI, Kaggle, Lightning',
      'Hugging Face, NLP',
      'TensorFlow, PyTorch, Colab',
      'ONNX, TensorRT, Gradio',
      'LLMs, Generative AI, Edge AI (NVIDIA Jetson)',
    ]
  },
  {
    title: 'NLP',
    desc: '15+ NLP modules. Multiple industry oriented and real world projects.',
    sub: 'Technologies covered:',
    points: [
      'NLP, Machine Learning',
      'Python for NLP',
      'LLMs, Prompt engineering',
      'Hugging Face, Transformers',
      'Multimodal models, LLAMA',
      'RAG, Generative AI',
    ]
  },
  {
    title: 'Computer Vision',
    desc: '15+ computer vision modules. Multiple industry oriented and real world projects.',
    sub: 'Technologies covered:',
    points: [
      'OpenCV, Computer vision',
      'Python for vision',
      'Vision AI, CNN',
      'YOLO, Deep learning',
      'Vision LLMs',
    ]
  },
  {
    title: 'Generative AI',
    desc: '15+ generative AI modules. Multiple industry oriented and real world projects.',
    sub: 'Technologies covered:',
    points: [
      'Generative AI, Python for Generative AI',
      'Deep learning, PyTorch',
      'Generative AI LLMs',
      'Stable Diffusion, LLAVA',
      'Image and video generative AI models',
    ]
  },
  {
    title: 'Software Engineering',
    desc: '10+ software engineering modules. Multiple industry oriented and real world projects.',
    sub: 'Technologies covered:',
    points: [
      'Linux, Networking',
      'Docker, GitHub',
      'Deployment and CI/CD',
      'End-to-end application development',
      'Python for software development',
      'Automation and scripting',
      'AI for software development',
    ]
  },
  {
    title: 'Robotics',
    desc: '15+ robotics modules. Multiple industry oriented and real world projects.',
    sub: 'Technologies covered:',
    points: [
      'Python for robotics, Applied AI',
      'Robot Operating System (ROS)',
      'Autonomous navigation',
      'Sensors and computer vision for robotics',
      'Real-time simulation, Drones',
      'Autonomous cars, etc.',
    ]
  }
];

const Methodology_list = [
  {
    list: [
      'Completely Instructor-led internship program',
      'Industry type project experience by developing and deploying projects on workstations, cloud servers, and edge devices',
      'Involves learning materials + projects with real world datasets and applications',
      'Online and offline engagement methodology',
      'Personal mentorship and feedback',
      'Interview preparation and profile building'
    ]
  }
];


const Method_list = [
  {
    title: '1.  Develop AI models and projects on local hardware - AI GPU workstations',
    img: img1
  },
  {
    title: '2.  Develop AI models and projects on cloud services',
    img: img2
  },
  {
    title: '3.  Deploy AI models and projects on Nvidia Edge AI hardware ',
    img: img3
  },
  {
    title: '4.  Interview preparation using our Generative AI interview preparation platform',
    img: img4
  }
];

const Takeaway_list = [
  {
    title: 'Industry type simulated environment in internship',
  },
  {
    title: 'Develop and deploy projects on Big AI Workstations, Cloud, and Edge GPU devices',
  },
  {
    title: 'Experience startup based environment while performing projects',
  },
  {
    title: 'Be industry ready after the internship with required skills and knowledge',
  },
  {
    title: 'Projects and content created by experienced engineers with avg 20+ years in industry',
  },
  {
    title: 'Get personal attention and guidance from experienced engineers from top tech companies',
  },
];

function Internship() {

  // const isScrolling = useRef(false);

  //   useEffect(() => {
  //       const handleScroll = () => {
  //           if (window.scrollY > 0) {
  //               isScrolling.current = true;
  //           }
  //       };

  //       window.addEventListener('scroll', handleScroll);

  //       const timer = setTimeout(() => {
  //           if (!isScrolling.current) {
  //               window.scrollTo({ top: 770, behavior: 'smooth' });
  //           }
  //       }, 5000); // 5 seconds

  //       return () => {
  //           window.removeEventListener('scroll', handleScroll);
  //           clearTimeout(timer);
  //       };
  //   }, []);
  // const navigate = useNavigate();

  // const handleRedirect = (id) => {
  //   navigate(id); // Navigate to the login page
  // };

    return (
      <div className='internship-container'>
        <Header2 />
        {/* <Fluid /> */}
        {/* <div className='internship-header'>
          <a href='/' className='header-logo'>
          <img className='App-logo' src={Logo} alt="DeepceptAI" />
          </a>
          <h1 className='internship-title'>Deepcept AI Internship</h1>
          <button className='intern-enroll-btn' onClick={() => handleRedirect('/contact')}>Enroll</button>
          <button className='intern-login-btn' onClick={() => handleRedirect('/login')}>Login</button>
        </div> */}
        <h1 className='internship-title'>Internship Program Offered</h1>
        <div className='internship-cont'>
          {Internship_list.map((intern, index) => (
            <div className='internship-card' key={index}>
              <h1 className='intern-title'>{intern.title}</h1>
              <p className='intern-desc'>{intern.desc}</p>
              <h2 className='intern-sub'>{intern.sub}</h2>
              <ul className='intern-points'>
                {intern.points.map((point, idx) => (
                  <li className='intern-point' key={idx}>{point}</li>
                ))}
                <p>And Many More</p>
              </ul>
            </div>
          ))}
        </div>
        <div className='method-cont'>
          <h1 className='internship-title'>Internship Methodology</h1>
          <div className='method-sub-cont'>
            <div className='method-cont1'>
              {Method_list.map((met, index) => (
                <div className='method-card1' key={index}>
                  <img className='method-img' src={met.img} alt={met.title}></img>
                  <h1 className='method-title1'>{met.title}</h1>
                </div>
              ))}
            </div>
            <div className='method-cont2'>
              {Methodology_list.map((method, index) => (
                <ul className='m-c2' key={index}>
                  {method.list.map((met, idx) => (
                    <li className='intern-desc1' key={idx}>{met}</li>
                  ))}
                </ul>
              ))}
            </div>
          </div>
        </div>
        <div className='takeaway'>
          <h1 className='internship-title'>What is Your Takeaway</h1>
          <div className='intern-cont1'>
            {Takeaway_list.map((take, index) => (
              <div className='internship-card2' key={index}>
                <p className='intern-title2'>{take.title}</p>
              </div>
            ))}
          </div>
        </div>
        <Testimonial />
      </div>
    );
  }
  
  export default Internship;