import React, { useState } from 'react';
import './Header.css';
import Logo from '../Assets/Images/Deepcept.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useUserAuth } from '../Firebase/UserAuthContext';

function Header() {
  const { logOut, user } = useUserAuth(); // Access the user and logOut function from context
  const navigate = useNavigate();

  // Handle logout and navigate back to login
  const handleLogout = async () => {
    try {
      await logOut();
      navigate('/'); // Redirect to the login page after logout
    } catch (error) {
      console.log(error.message);
    }
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle the mobile menu open and close
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Smooth scroll function for links
  const handleScroll = () => {
    setTimeout(() => {
      window.scrollTo({ top: 770, behavior: 'smooth' });
    }, 10);
    setIsMenuOpen(false); // Close menu after clicking a link
  };

  return (
    <div className='header'>
      <div className='head-content'>
        <Link to='/' className='link' onClick={handleScroll}>
          <img className='App-logo' src={Logo} alt="DeepceptAI" />
        </Link>
        <div className='hamburger' onClick={handleMenuToggle}>
          ☰
        </div>
        <div className={`mode ${isMenuOpen ? 'open' : ''}`}>
          <div className='nav-content'>
            <ul>
              {/* Conditionally render Interview Prep only if user is logged in */}
              <li>
                  <Link to='/home' className='menu-item' onClick={handleScroll}>
                    Home
                  </Link>
                </li>
              {user && (
                <li>
                  <Link to='/Interview' className='menu-item' onClick={handleScroll}>
                    Interview Prep
                  </Link>
                </li>
              )}
              {/* Render logout button only if user is logged in */}
              {user && (
                <li>
                  <button onClick={handleLogout} className="logout-button">Logout</button>
                </li>
              )}

              {/* If user is not logged in, show Login */}
              {!user && (
                <li>
                  <Link to='/login' className='menu-item' onClick={handleScroll}>Login</Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
