import React from 'react'
import './Contact.css'

function Contact() {
  return (
    <div id='contact' className='contact-container'>
      <h1 className='contact-title'>Contact Us</h1>
      <h3 className='contact-sub'>Whether you have a question or just want to say hi, Our inbox is always open!</h3>
      {/* Replace email@example.com with the desired email address */}
      <a href='mailto:contact@deepcept.com'>
        <button className='contact-btn'>Contact Us</button>
      </a>
    </div>
  )
}

export default Contact
