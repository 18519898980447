import React, { Suspense } from 'react';
import { HashRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { UserAuthContextProvider, useUserAuth } from './Components/Firebase/UserAuthContext';
import Login from './Components/Firebase/Login';
import './App.css';
// import Navbar from './Components/Header/Header'
import routesConfig from './RoutesConfig';
import Header from './Components/HeaderMain/Header';
import Header1 from './Components/Header/Header'
import Headercontact from './Components/HeaderMain/Header1';
import Top from './Components/Top';
import Home from './Components/HomeMain/Home';
import Home1 from './Components/Home/Home'
import Home2 from './Components/Home2/Home2';
import Ydeep from './Components/Ydeep/Ydeep';
import Products from './Components/Products/Products';
import About from './Components/About/About';
import Service from './Components/Services/Services';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';
import Internship from './Components/Internship/Internship';
import Announcement from './Components/Announcement/Announcement'

import PDF1 from './Components/Announcement/PDFReader/Pdf1'
import PDF2 from './Components/Announcement/PDFReader/Pdf2'
import PDF3 from './Components/Announcement/PDFReader/Pdf3'


function ProtectedRoute({ children }) {
  const { user, loading } = useUserAuth();
  // Show a loading spinner or message while authentication status is being checked
  if (loading) {
    return <div>Loading...</div>; // You can replace this with a spinner or any loading indicator
  }

  // If user is not authenticated, redirect to login
  return user ? children : <Navigate to="/login" />;
}

const App = () => {
  const { user } = useUserAuth();
  const location = useLocation();

  return (
    <div className='App'>
      <Top />
      <Suspense fallback={<div><h1>Loading...</h1></div>}>
        <Routes>
          <Route path="/" element={
              <>
                <Header />
                {location.pathname !== '/internship' && (
                  <>
                    <Home id='home' />
                    <Home2 id='home2' />
                    <Products id='products' />
                    <Service id='service' />
                    <Ydeep />
                    <About id='about' />
                    <Contact id='contact' />
                  </>
                )}
                <Footer />
              </>
            } />
          <Route path="/login" element={user ? <Navigate to="/home"/>:<><Header1 /><Login /></>} />
          <Route path="/internship" element={<><Internship /><Footer /></>} />
          <Route path="/Announcement" element={<><Announcement /></>} />
          <Route path="/home" element={<><Header1 /><Home1 /></>} />
          <Route path="/contact" element={<><Headercontact /><Contact /><Footer /></>} />
          <Route path="/form.pdf" element={<><Header /><PDF1 /><Footer /></>} />
          <Route path="/research1.pdf" element={<><Header /><PDF2 /><Footer /></>} />
          <Route path="/research2.pdf" element={<><Header /><PDF3 /><Footer /></>} />

          {routesConfig.map(({ path, element }, index) => (
            <Route key={index} path={path} element={<ProtectedRoute>{<><Header1 />{element}</>}</ProtectedRoute>} />
          ))}
          <Route path="/" element={<Navigate to={user ? "/home" : "/login"} />} />
        </Routes>
      </Suspense>
    </div>
  );
};

const AppWrapper = () => (
  <UserAuthContextProvider>
      <Router> {/* Wrap everything inside Router */}
      {/* <Navbar /> */}
        <Suspense fallback={<div>Loading...</div>}> {/* Handle lazy-loaded component states */}
          <App />
        </Suspense>
      </Router>
    </UserAuthContextProvider>
);

export default AppWrapper;
