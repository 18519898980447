import React, { useEffect, useState, useRef } from 'react';
import './Home.css';
import { useUserAuth } from "../Firebase/UserAuthContext";
import { ref, get } from "firebase/database";
import { db } from "../Firebase/firebase";
import { Link } from 'react-router-dom';
import Fluid from '../Fluid/Fluid';

import Img1 from '../Assets/Images/data_analytics.jpg';
import Img2 from '../Assets/Images/data_science.jpg';
import Img3 from '../Assets/Images/Computer_vision.jpg';
import Img4 from '../Assets/Images/software_development.jpeg';
import Img5 from '../Assets/Images/coreai.png';
import Img6 from '../Assets/Images/ML.jpg';
import Img7 from '../Assets/Images/dl.jpg';
import Img8 from '../Assets/Images/GenAI.jpg';
import Img9 from '../Assets/Images/NLP.jpg';
import Img10 from '../Assets/Images/robotics.png';

const courses = [
    {
        id: 1,
        title: "Data Analytics",
        description: "Explore the world of AI and what this course offers",
        points: [],
        img: Img1,
        link: '/Data_Analytics'
    },
    {
        id: 2,
        title: "Data Science",
        points: [],
        img: Img2,
        link: '/Data_Science'
    },
    {
        id: 3,
        title: "Computer vision",
        points: [],
        img: Img3,
        link: '/Computer_Vision'
    },
    {
        id: 4,
        title: "Software Development",
        points: [],
        img: Img4,
        link: '/Software_development'
    },
    {
        id: 5,
        title: "AI",
        points: [],
        img: Img5,
        link: '/AI'
    },
    {
        id: 6,
        title: "Machine Learning",
        points: [],
        img: Img6,
        link: '/MachineLearning'
    },
    {
        id: 7,
        title: "Deep Learning",
        points: [],
        img: Img7,
        link: '/DL'
    },
    {
        id: 8,
        title: "Generative AI",
        points: [],
        img: Img8,
        link: '/Gen_AI'
    },
    {
        id: 9,
        title: "NLP",
        points: [],
        img: Img9,
        link: '/NLP'
    },
    {
        id: 10,
        title: "Robotics",
        points: [],
        img: Img10,
        link: '/Robotics'
    }
];

function Home() {
    const { user } = useUserAuth();
    const [userCourse, setUserCourse] = useState(null); // Renamed to avoid conflict
  
    useEffect(() => {
        const fetchCourseData = async () => {
            if (user && user.email) {
                const email = user.email.replace('.', '_');
                const dbRef = ref(db, `users/${email}`);
                try {
                    const snapshot = await get(dbRef);
                    if (snapshot.exists()) {
                        const userData = snapshot.val();
                        setUserCourse(userData.course); // Update state with user course
                    } else {
                        console.log("No data available");
                    }
                } catch (error) {
                    console.error("Error fetching course data: ", error);
                }
            }
        };
        fetchCourseData();
    }, [user]);

    const isScrolling = useRef(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                isScrolling.current = true;
            }
        };

        window.addEventListener('scroll', handleScroll);

        const timer = setTimeout(() => {
            if (!isScrolling.current) {
                window.scrollTo({ top: 770, behavior: 'smooth' });
            }
        }, 5000); // 5 seconds

        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(timer);
        };
    }, []);

    return (
        <div className='h-m'>
            <Fluid />
            <h1 className='H1'>
                Internship Modules
                {userCourse && (
                    <div>
                        <br />
                        Welcome! Your Course: <strong>{userCourse}</strong>
                    </div>
                )}
            </h1>

            <div className='home-items'>
                {courses.map((course) => (
                    <div className='home-anchor' key={course.id}>
                        {userCourse === course.title ? (
                            <Link to={course.link} className='link'>
                                <img className='c-img' src={course.img} alt={course.title} />
                                <h2>{course.title}</h2>
                                <p className='description'>
                                    {course.description}
                                    <ul>
                                        {course.points.map((point, index) => (
                                            <li key={index}>{point}</li>
                                        ))}
                                    </ul>
                                </p>
                            </Link>
                        ) : (
                            <div className='disabled-link'>
                                <img className='c-img' src={course.img} alt={course.title} />
                                <h2>{course.title}</h2>
                                <p className='description'>
                                    {course.description}
                                    <ul>
                                        {course.points.map((point, index) => (
                                            <li key={index}>{point}</li>
                                        ))}
                                    </ul>
                                </p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Home;